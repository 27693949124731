import { CBadge } from "@coreui/react-pro";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { CircleLoader } from "react-spinners";
import TrainerTable from "./TrainersTable";
import TeamsTable from "./TeamsTable";

const EnterpriseDetails = (props) => {
  const accessToken = `Bearer ${localStorage.getItem("token")}`;
  const lang = props.Language;
  const navigate = useNavigate();
  const params = useParams();
  const CMS_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

  const [EnterpriseFound, setEntepriseFound] = useState(true);
  const [Enteprisejson, setEnterprisejson] = useState(null);

  const handleSingleEnterprise = async () => {
    try {
      const result = await fetch(
        `${CMS_ENDPOINT}/api/enterprise/${params.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result.ok) {
        setEntepriseFound(true);
        let data = await result.json();
        setEnterprisejson(data);
      } else {
        setEntepriseFound(false);
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Fetch singleEnterprise -", error);
    }
  };

 
  useEffect(() => {
    handleSingleEnterprise();
    // handleGetAllTrainersByEnterprise();
  }, []);

  //   return <>Enterprise details</>;

  return EnterpriseFound ? (
    Enteprisejson ? (
      <>
        <div className="container-fluid">
          {/* Page Heading */}
          <div className=" d-flex justify-content-between">
            <div>
              <h2 className="h3 mb-2 text-gray-800">Enterprise</h2>
              <p className="mt-3">
                <span className="BackButton" onClick={() => navigate(-1)}>
                  <i className="fas fa-arrow-left backbtn"></i>
                </span>
                <span className="h2 mb-2 text-secondary ">
                  #{Enteprisejson.EnterpriseID}
                </span>
                {/* <CBadge color="dark text-white" className="CBadge">
                  {capitalizeWords(Orderjson[0]?.tags ?? "")}
                </CBadge>{" "} */}
                <CBadge color="info text-white" className="CBadge">
                  {/* {fulfilled} */}
                </CBadge>
              </p>
              <p className="mb-2 text-secondary">{/* {OrderDate}  */}</p>
            </div>
          </div>

          <div className="order-detail-body">
            <div key={`singleEnterprise`}>
              <div className="row">
                <div className="col-md-12">
                  <div className="card shadow mb-4"></div>
                </div>

                <div className="col-md-4">
                  <div className="card shadow mb-4">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <h5 className="order-details-title">
                            {lang === "en" ? "Basic Info" : "基本信息"}
                          </h5>
                          <div>
                            <ul className="enterprisedetails">
                              <li>Name : {Enteprisejson.EnterpriseName}</li>
                              <li>Email : {Enteprisejson.Email}</li>
                              <li>Phone : {Enteprisejson.Phone}</li>
                              <li>City : {Enteprisejson.City}</li>
                              <li>State : {Enteprisejson.State}</li>
                              <li>Zip : {Enteprisejson.Zip}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card shadow mb-4">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <h5 className="order-details-title">
                            {lang === "en" ? "Other Information" : "其他信息"}
                          </h5>
                          <div>
                            <ul className="enterprisedetails">
                              <li>
                                <span>Enterprise Type</span> :
                                {Enteprisejson.EnterpriseType}
                              </li>
                              <li>
                                <span>EmailNotification</span> :
                                {Enteprisejson.EmailNotification
                                  ? "Enabled"
                                  : "Disabled"}
                              </li>
                              <li>
                                <span>TextNotification</span> :
                                {Enteprisejson.TextNotification
                                  ? "Enabled"
                                  : "Disabled"}
                              </li>
                              <h className="order-details-title">Permissions</h>
                              <li>
                                <span>Can Create Teams</span> :
                                {Enteprisejson?.Permissions?.CanCreateTeam
                                  ? "Enabled"
                                  : "Disabled"}
                              </li>
                              <li>
                                <span>Can Create Orders</span> :
                                {Enteprisejson?.Permissions?.CanCreateOrders
                                  ? "Enabled"
                                  : "Disabled"}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card shadow mb-4">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <h5 className="order-details-title">
                            {lang === "en" ? "Shipping Address" : "邮寄地址"}
                          </h5>
                          <div>
                            <p> {Enteprisejson.ShippingAddress}</p>
                            <br />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h5 className="order-details-title">
                            {lang === "en" ? "Billing Address" : "帐单地址"}
                          </h5>
                          <div>
                            <p>{Enteprisejson.BillingAddress}</p>
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  Table for Trainers*/}
              <div className="row">
                <TrainerTable Language={lang} enterpriseId={params.id} />
              </div>

              {/*  Table for Teams*/}
              <div className="row">
                <TeamsTable Language={lang} enterpriseId={params.id} />
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      </>
    ) : (
      <>
        <div style={{ height: "100%" }} role="status">
          <CircleLoader className="LoaderSingleOrder" color="#1ca1ee" />
        </div>
      </>
    )
  ) : (
    <div>
      <h1 className="text-dark mx-auto">
        {lang === "en" ? "Enterprise Not Found" : "未找到企业"}
      </h1>
    </div>
  );
};

export default EnterpriseDetails;
