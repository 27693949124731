import { CSmartTable } from "@coreui/react-pro";
import { useEffect, useMemo, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  capitalizeWords,
  changeGMTFormate,
  StatusConversion,
} from "../../Components/allFunctions";

const CMS_ENDPOINT =
  process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_CMS_ROUTE;

const FulFilledOrder = (props) => {
  const lang = props.Language;
  const params = useParams();

  const [fullfillesData, setFullfilledData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [accessToken, setAccessToken] = useState("");

  const [OrderStatus, setOrderStatus] = useState("");

  const navigate = useNavigate();
  const changeHandler = (e) => {
    setOrderStatus(e.target.value);
    navigate(e.target.value);
  };
  const customItemsPerPageLabel =
    lang === "en" ? "Items per Page:" : "项目/页 : ";
  const customTableFilterLabel = lang === "en" ? "Filter" : "筛选";

  const columns = [
    // {
    //   key: "ID",
    //   label: "ID",
    //   filter: false,
    //   sorter: false,
    // },
    {
      key: "OrderName",
      label: lang === "en" ? "Order" : "订单",
    },
    {
      key: "CreatedAt",
      label: lang === "en" ? "Date" : "日期",
    },
    {
      key: "CustomerName",
      label: lang === "en" ? "Customer" : "客户",
    },
    {
      key: "FulfillmentStatus",
      label: lang === "en" ? "Fulfillment Status" : "进度状态",
    },
    {
      key: "Items",
      label: lang === "en" ? "Items" : "项目",
    },
    {
      key: "TeamName",
      label: lang === "en" ? "Team" : "团队",
    },
    {
      key: "EnterpriseName",
      label: lang === "en" ? "Enterprise" : "企业",
    },
    {
      key: "Flag",
      label: lang === "en" ? "Flagged" : "标记",
    },
    {
      key: "FlagBy",
      label: lang === "en" ? "Flagged By" : "以xx标记",
    },
    {
      key: "messageCount",
      label: lang === "en" ? "Message Count" : "消息數",
    },
  ];

  const getFullfilledOrders = () => {
    try {
      setLoading(true);
      let email = localStorage.getItem("email");
      let id = localStorage.getItem("id");

      if (email !== null && id !== null) {
        fetch(
          `${CMS_ENDPOINT}/orders/status/${params?.status}?email=${email}&id=${id}`
        )
          .then((response) => response.json())
          .then((fullfillesData) => {
            setFullfilledData(fullfillesData.orders);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setLoading(false);
        console.log("Please Login First");
      }
    } catch (error) {}
  };

  const sortedOrders = useMemo(() => {
    return [...fullfillesData].sort(
      (a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt)
    );
  }, [fullfillesData]);

  useEffect(() => {
    getFullfilledOrders();
  }, [params?.status]);

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800 HeadTitles">
          {lang === "en" ? "Orders" : "订单"}
        </h1>
      </div>

      {/* <div className="row">
        <div className="col-xl-12 col-md-12 mb-12">
          <h3 className="SubHeadTitles">{StatusConversion(params?.status, lang)}</h3>
          <div className="card shadow mb-4 flaged">
            <div className="card-body orders-table">
              <div className="tableDropDown">
              <div className="DropdownTittle">{lang==="en" ? "Order Status":"訂單狀態"}</div>
                <select
                  className="custom-select"
                  value={OrderStatus}
                  onChange={changeHandler}
                >
                  <option value="" disabled>
                  {lang==="en" ? "Order Status":"訂單狀態"}
                  </option>
                  <option value="/orders">{lang==="en" ? "All Orders":"所有訂單"}</option>
                  <option value="/orders/awaiting-review">{lang==="en" ? "Awaiting Review":"等待审核"}</option>
                  <option value="/orders/reviewed">{lang==="en" ? "Reviewed":"已审核"}</option>
                  <option value="/orders/manufactured">{lang==="en" ? "Manufactured":"已生产"}</option>
                  <option value="/orders/quality-assured">{lang==="en" ? "Quality Assured":"质量已把关"}</option>
                  <option value="/orders/packaging">{lang==="en" ? "Packaging":"包装"}</option>
                  <option value="/orders/shipped">{lang==="en" ? "Shipped":"已发货"}</option>
                  <option value="/orders/fulfilled">{lang==="en" ? "Fulfilled":"實現了"}</option>
                </select>
              </div>
              <CSmartTable
                responsive="true"
                items={fullfillesData}
                columns={columns}
                columnSorter
                tableFilter
                tableFilterPlaceholder={lang==="en" ? "Search...":"搜索..."}
                pagination
                loading={loading}
                itemsPerPage={20}
                itemsPerPageSelect
                itemsPerPageLabel={customItemsPerPageLabel}
                tableProps={{
                  hover: true,
                  responsive: true,
                  striped: true,
                  className: "flagged-table",
                }}
                scopedColumns={{
                  
                  ID: (item, i) => {
                    const flaggedClassName =
                     
                      item.Flag === 1 ? "" : "";
                    return <td className={flaggedClassName}>{i + 1}</td>;
                  },
                  OrderName: (items) => {
                    const flaggedClassName =
                     
                      items.Flag === 1 ? "" : "";
                    return (
                      <td className={flaggedClassName}>
                        <Link
                          to={`/order/${items.OrderId}`}
                          className={flaggedClassName}
                        >
                          {items.OrderName}
                        </Link>
                      </td>
                    );
                  },
                  Flag: (items) => {
                    const flaggedClassName =
                      // items.Flag === 1 ? "flagged-row" : "";
                      items.Flag === 1 ? "" : "";
                    return (
                      <td className={flaggedClassName}>
                        {items.Flag === 1 ? "Flag" : ""}
                      </td>
                    );
                  },
                  FlagBy: (items) => {
                    const flaggedClassName =
                    
                      items.Flag === 1 ? "" : "";
                    return (
                      <td className={flaggedClassName}>
                        {items.FlagBy == "null" || items.Flag === 0
                          ? ""
                          : capitalizeWords(items.FlagBy)}
                      </td>
                    );
                  },
                  FulfillmentStatus: (items) => {
                    const flaggedClassName =
                    
                      items.Flag === 1 ? "" : "";
                    return (
                      <td className={flaggedClassName}>
                       
                        {StatusConversion(items.FulfillmentStatus, lang)}
                      </td>
                    );
                  },
                  CustomerName: (items) => {
                    const flaggedClassName =
                      
                      items.Flag === 1 ? "" : "";
                    return (
                      <td className={flaggedClassName}>
                        {capitalizeWords(items.CustomerName)}
                      </td>
                    );
                  },
                  CreatedAt: (items) => {
                    const flaggedClassName =
                      
                      items.Flag === 1 ? "" : "";
                    return (
                      <td className={flaggedClassName}>{items.CreatedAt}</td>
                    );
                  },

                  Items: (items) => {
                    const flaggedClassName =
                   
                      items.Flag === 1 ? "" : "";
                    return (
                      <td className={flaggedClassName}>
                        {capitalizeWords(items.Items)}
                      </td>
                    );
                  },
                  Tag: (items) => {
                    const flaggedClassName =
              
                      items.Flag === 1 ? "" : "";
                    return (
                      <td className={flaggedClassName}>
                        {capitalizeWords(items.Tag)}
                      </td>
                    );
                  },
                  MessageCount: (items) => {
                    const flaggedClassName =
                  
                      items.Flag === 1 ? "" : "";
                    return (
                      <td className={flaggedClassName}>
                        <div className="OrderMessageCount">{(items.messageCount)}</div>
                        
                      </td>
                    );
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div> */}

      <div className="row">
        <div className="col-xl-12 col-md-12 mb-12">
          <h3 className="SubHeadTitles">
            {StatusConversion(params?.status, lang)}
          </h3>

          <div className="card shadow mb-4 flaged">
            <div className="card-body orders-table">
              <div className="tableDropDown">
                <div className="DropdownTittle">
                  {lang === "en" ? "Order Status" : "訂單狀態"}
                </div>
                <select
                  className="custom-select"
                  value={OrderStatus}
                  onChange={changeHandler}
                >
                  <option value="" disabled>
                    {lang === "en" ? "Order Status" : "訂單狀態"}
                  </option>
                  <option value="/orders">
                    {lang === "en" ? "All Orders" : "所有訂單"}
                  </option>
                  <option value="/orders/awaiting-review">
                    {lang === "en" ? "Awaiting Review" : "等待审核"}
                  </option>
                  <option value="/orders/reviewed">
                    {lang === "en" ? "Reviewed" : "已审核"}
                  </option>
                  <option value="/orders/manufactured">
                    {lang === "en" ? "Manufactured" : "已生产"}
                  </option>
                  <option value="/orders/quality-assured">
                    {lang === "en" ? "Quality Assured" : "质量已把关"}
                  </option>
                  <option value="/orders/packaging">
                    {lang === "en" ? "Packaging" : "包装"}
                  </option>
                  <option value="/orders/shipped">
                    {lang === "en" ? "Shipped" : "已发货"}
                  </option>
                  <option value="/orders/fulfilled">
                    {lang === "en" ? "Fulfilled" : "實現了"}
                  </option>
                </select>
              </div>

              <div className="custom-table">
                {/* <label htmlFor="tableFilter">{customTableFilterLabel}</label> */}
                <CSmartTable
                  responsive="true"
                  items={sortedOrders}
                  columns={columns}
                  columnSorter
                  tableFilter
                  tableFilterPlaceholder={
                    lang === "en" ? "Search..." : "搜索..."
                  }
                  tableFilterLabel={customTableFilterLabel}
                  pagination
                  loading={loading}
                  itemsPerPage={20}
                  itemsPerPageSelect
                  itemsPerPageLabel={customItemsPerPageLabel}
                  tableProps={{
                    hover: true,
                    responsive: true,
                    striped: true,
                    className: "flagged-table",
                  }}
                  scopedColumns={{
                    // ID: (item, i) => <td>{i + 1}.</td>,
                    // ID: (item, i) => {
                    //   const flaggedClassName =
                    //     // item.Flag === 1 ? "flagged-row" : "";
                    //     item.Flag === 1 ? "" : "";
                    //   return <td className={flaggedClassName}>{i + 1}</td>;
                    // },
                    OrderName: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1 ? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";
                      return (
                        <td className={flaggedClassName}>
                          <Link
                            to={`/order/${items.OrderId}`}
                            className={flaggedClassName}
                          >
                            {items.OrderName}
                          </Link>
                        </td>
                      );
                    },
                    Flag: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1 ? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";
                      return (
                        <td className={flaggedClassName}>
                          {items.Flag === 1
                            ? lang === "en"
                              ? "Flag"
                              : "旗幟"
                            : ""}
                        </td>
                      );
                    },
                    FlagBy: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1 ? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";
                      return (
                        <td className={flaggedClassName}>
                          {items.FlagBy == "null" || items.Flag === 0
                            ? ""
                            : capitalizeWords(items.FlagBy)}
                        </td>
                      );
                    },
                    FulfillmentStatus: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1 ? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";
                      return (
                        <td className={flaggedClassName}>
                          {/* {capitalizeWords(items.FulfillmentStatus)} */}
                          {StatusConversion(items.FulfillmentStatus, lang)}
                        </td>
                      );
                    },
                    CustomerName: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1 ? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";
                      return (
                        <td className={flaggedClassName}>
                          {capitalizeWords(items.CustomerName)}
                        </td>
                      );
                    },
                    CreatedAt: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1 ? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";
                      return (
                        <td className={flaggedClassName}>
                          {changeGMTFormate(items.CreatedAt)}
                        </td>
                      );
                    },

                    Items: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";
                      return (
                        <td className={flaggedClassName}>
                          {capitalizeWords(items.Items)}
                        </td>
                      );
                    },
                    // Vendors: (items) => {
                    //   const flaggedClassName =
                    //     // items.Flag === 1? "flagged-row" : "";
                    //     items.Flag === 1 ? "" : "";
                    //   return (
                    //     <td className={flaggedClassName}>
                    //       {capitalizeWords(items.Tag)}
                    //     </td>
                    //   );
                    // },
                    TeamName: (items) => {
                      return <td>{capitalizeWords(items.TeamName)}</td>;
                    },
                    EnterpriseName: (items) => {
                      return <td>{capitalizeWords(items.EnterpriseName)}</td>;
                    },
                    messageCount: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1 ? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";
                      return (
                        <td className={flaggedClassName}>
                          {items.messageCount != "0" ? (
                            <div className="OrderMessageCount">
                              {items.messageCount}
                            </div>
                          ) : lang === "en" ? (
                            "No Message"
                          ) : (
                            "沒有留言"
                          )}
                        </td>
                      );
                    },

                    // updated_at: (items) => {
                    //   return (
                    //     <td>
                    //       {new Date(items.updated_at).toLocaleDateString("en-GB")}
                    //     </td>
                    //   );
                    // },
                    // customer: (items) => {
                    //   return (
                    //     <td>
                    //       {capitalizeWords(
                    //         items?.customer?.first_name +
                    //           " " +
                    //           items?.customer?.last_name ?? ""
                    //       )}
                    //     </td>
                    //   );
                    // },
                    // fulfillment_status: (items) => {
                    //   return (
                    //     <td>
                    //       {items.fulfillment_status == null
                    //         ? "Unfulfilled"
                    //         : capitalizeWords(items.fulfillment_status ?? "")}
                    //     </td>
                    //   );
                    // },
                    // tags: (items) => {
                    //   return <td>{capitalizeWords(items?.tags ?? "")}</td>;
                    // },
                    // line_items: (items) => {
                    //   let total_item = items.line_items.reduce(
                    //     (total, item) => total + item.quantity,
                    //     0
                    //   );
                    //   return <td>{total_item}</td>;
                    // },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FulFilledOrder;
