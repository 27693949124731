import { CSmartTable } from "@coreui/react-pro";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const TeamsTable = (props) => {
  const lang = props.Language;
  const EnterpriseID = props.enterpriseId;

  const [loading, setLoading] = useState(false);
  const [Teams, setTeams] = useState(null);

  const customItemsPerPageLabel =
    lang === "en" ? "Items per Page:" : "项目/页 : ";
  const customTableFilterLabel = lang === "en" ? "Filter" : "筛选";
  console.log(Teams);

  const columns = [
    {
      key: "id",
      label: "ID",
      filter: false,
      sorter: false,
    },
    {
      key: "TeamID",
      label: lang === "en" ? "TeamID" : "团队ID", // 团队ID for "TeamID"
    },

    {
      key: "EnterpriseID",
      label: lang === "en" ? "EnterpriseID" : "企业ID", // Correct for "EnterpriseID"
    },
    {
      key: "TeamName",
      label: lang === "en" ? "TeamName" : "团队名称", // 团队名称 for "TeamName"
    },
    {
      key: "PrimaryName",
      label: lang === "en" ? "PrimaryName" : "主要姓名", // 主要姓名 for "PrimaryName"
    },
    {
      key: "PrimaryEmail",
      label: lang === "en" ? "PrimaryEmail" : "主要电子邮件", // 主要电子邮件 for "PrimaryEmail"
    },
    {
      key: "PrimaryPhone",
      label: lang === "en" ? "PrimaryPhone" : "主要电话", // 主要电话 for "PrimaryPhone"
    },
  ];

  const handleGetAllTeamsByEnterprise = async () => {
    try {
      setLoading(true);

      const result = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/teams/u/${EnterpriseID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let data = await result.json();
      if (result.ok) {
        setTeams(data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      toast.error(error.message);
      console.log("Fetch all Trainer by enterprise -", error);
    }
  };

  useEffect(() => {
    handleGetAllTeamsByEnterprise();
  }, []);

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800 HeadTitles">
          {lang === "en" ? "Teams" : "运动鞋"}
        </h1>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 mb-12">
          <div className="card shadow mb-4 flaged">
            <div className="card-body orders-table">
              <div className="custom-table">
                <CSmartTable
                  responsive="true"
                  items={Teams}
                  columns={columns}
                  columnSorter
                  tableFilter
                  tableFilterPlaceholder={
                    lang === "en" ? "Search..." : "搜索..."
                  }
                  tableFilterLabel={customTableFilterLabel}
                  pagination
                  loading={loading}
                  itemsPerPage={20}
                  itemsPerPageSelect
                  itemsPerPageLabel={customItemsPerPageLabel}
                  tableProps={{
                    hover: true,
                    responsive: true,
                    striped: true,
                    className: "flagged-table",
                  }}
                  scopedColumns={{
                    id: (item, i) => {
                      return <td>{i + 1}.</td>;
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamsTable;
